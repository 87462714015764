import React                        from 'react';
import { useDispatch, useSelector }  from "react-redux";
import { useTranslation }           from "react-i18next";

import { setModalForm, setShowMenu } from "../../storage/global";
import ProfileForm                   from "../forms/ProfileForm";

const ProfileButton = ({className}) => {

    const {t} = useTranslation();

    const profile = useSelector(state => state.global.profile)
    const dispatch = useDispatch();

    function openForm() {
        dispatch(setShowMenu(false))

        dispatch(setModalForm({
            title: t('profile'),
            form: <ProfileForm/>,
            formContent: {classNames: 'modal-form-content-profile'},
        }))
    }

    return (
        <div className={className}>
            <div onClick={openForm}>
                <img src="/assets/img/profile.svg" alt="profile" title='profile'/>
                <span className={'my-text-primary ml-2'}>{profile.username} ({profile.usercode})</span>
            </div>
        </div>
    );
};

export default ProfileButton;
