import React           from 'react';
import { useDispatch } from "react-redux";
import { useTranslation }           from "react-i18next";

import HelpVideo       from "./HelpVideo";
import Logo            from "../shared/Logo";
import BurgerMenu      from "../shared/BurgerMenu";
import { setShowMenu, setModalForm } from "../../storage/global";
import ButtonAdmin      from "../shared/pages_buttons/ButtonAdmin";
import FeedbackForm     from "../forms/FeedbackForm";

const HeaderSmall = ({className}) => {
    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();

    function showMenu(e) {
        dispatch(setShowMenu(true))
    }

    function writeReport() {
        dispatch(setModalForm({
            title: <div style={{wordWrap: "break-word", marginRight: '46px'}}>{t('feedback_title')}</div>,
            form: <FeedbackForm/>,
            formBody: {classNames: 'modal-form-body-feedback-small'},
            formContent: {classNames: ''},
            hideOut: true
        }))
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className={'col d-flex justify-content-start'}>
                <BurgerMenu className={'cursor-pointer'} handle={showMenu}/>
                <div onClick={writeReport}
                     className={` ml-1`}
                     style={{
                        width: '70px',
                        height: '24px',
                     }}
                >
                    <div className={'cursor-pointer'}
                         style={{
                            width: '100%',
                            marginTop: '3px',
                         }}
                    >
                        <img className={'w-100'} src={`/assets/img/feedback.png`} alt="new"/>
                    </div>
                </div>
            </div>
            <Logo className={'col d-flex justify-content-center align-items-center'} styleLogo={{
                width: '72px'
            }}/>
            <div className={'col d-flex justify-content-end'}>
                <HelpVideo className={''}/>
            </div>
        </div>
    );
};

export default HeaderSmall;
