import React, { useState, useEffect, useMemo }      from 'react';
import { useDispatch, useSelector }                 from "react-redux";
import { useTranslation }                           from "react-i18next";
import { Button }                                   from "react-bootstrap";
import moment                                       from "moment/moment";

import {
    DATE,
    COUNT,
}                                                       from "../../../constants";
import axios                                            from "../../../lib/axios";
import {
    setLoad,
    setLoadSpinner,
    defHeadNotificationBarSettings,
    SET_KEY
}  from "../../../storage/global";
import { makePostRequest, makeGetRequest}                               from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import CheckBox                                         from "../../../components/input/CheckBox";
import InputText                                        from "../../../components/input/InputText";
import Select                                        from "../../../components/input/Select";
import RequestStatistic                                 from "../../../components/shared/RequestSettings/RequestStatistic";
import { Upload }                                       from "../../../components/shared/Upload";

const HeadNotificationBarSettings = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const headNotificationBarSettings = useSelector(state => state.global.headNotificationBarSettings)
    const fakeDoorsIcon = useSelector(state => state.global.fakeDoorsIcon)
    const fakeDoorsModalBG = useSelector(state => state.global.fakeDoorsModalBG)

    const [inSaveProces, setInSaveProces] = useState(false);
    const [inDownloadProces, setInDownloadProces] = useState(false);
    const [dateStatHeadNotificationBar, setDateStatHeadNotificationBar] = useState({});
    const [dateStatHeadNotificationBarId, setDateStatHeadNotificationBarId] = useState('');

    useEffect(async () => {
        await makeGetRequest('/api/admin/getFakeDoorsStatistic', {
            query: '/api/misc/fakeDoorsUserAction'
        }, {})
            .then(({data}) => {
                setDateStatHeadNotificationBar(data);
                setDateStatHeadNotificationBarId(Object.keys(data)[0])
            })
    }, [])

    const statHeadNotificationBar = useMemo(() => {
        const dates = dateStatHeadNotificationBar[dateStatHeadNotificationBarId];
        const datesList = dates && !dates.length ? Object.keys(dates) : [];
        return datesList.map( key => {
            const count = dates[key];
            return {[DATE]: key, [COUNT]: count}
        }) || []
    }, [dateStatHeadNotificationBar, dateStatHeadNotificationBarId])

    function setKeyBar(key, value) {
        dispatch({type: SET_KEY, payload: {
            key: 'headNotificationBarSettings',
            value: {...headNotificationBarSettings, [key]: value},
        }})
    }

    const listHead = [
        {name: DATE, col: 10, text: 'Дата', sortArrow: false, classNames: 'wfc minw-100'},
        {name: COUNT, col: 5, text: 'Кількість кліків', sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const listItem = [
        {name: DATE, col: 10, classNames: 'wfc minw-100 py-1'},
        {name: COUNT, col: 5, classNames: 'wfc minw-100 py-1'},
    ]

    function saveImg(imagePreviewUrl, imgData, key = 'fakeDoorsIcon') {
        const image_data = imagePreviewUrl?.split('base64,')[1];
        const image_type = imagePreviewUrl?.split('base64,')[0].split('/')[1].slice(0, -1);
        const imageObj = { image_type: image_type || imgData.type, image_name: imgData?.name, image_data };
        dispatch({type: SET_KEY, payload: {
            key,
            value: imageObj,
        }})
    }

    function saveHeadNotificationBarSettings() {
        setInSaveProces(true)
        makePostRequest('/api/admin/saveHeadNotificationBarSettings',
            {head_notification_bar_settings: {...headNotificationBarSettings, fakeDoorsIcon, fakeDoorsModalBG}},
        {}).then((res) => {
            if(res.status === 200) {
                viewAlert(dispatch, res.response, {text: t('success').toLowerCase()})
            } else {
                viewAlert(dispatch, res.response)
            }
        })
        .catch(({response}) => {
            console.log('saveHeadNotificationBarSettings catch: ', response)
            viewAlert(dispatch, {"err": response})
        })
        .finally(() => {
            setInSaveProces(false)
        })
    }

    function loadExceldNotificationBar() {
        makePostRequest('/api/admin/printDateStatHeadNotificationBarId', {
            dateStatHeadNotificationBarId,
            datesStatistic: statHeadNotificationBar,
        }, {responseType: 'blob'})
            .then(({data}) => {
                setDateStatHeadNotificationBar(data);
                setDateStatHeadNotificationBarId(Object.keys(data)[0])
                const date = moment();
                const filename = `fake_doors_analytics_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
                const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename ? filename : 'file.xls');
                document.body.appendChild(link);
                link.click();
            })
    }

    return (
        <div className={`p-3 row`}>
            <div className="col-6">
                <CheckBox label={'Notification bar is active'}
                          id={'headNotificationBar_isActive'}
                          value={headNotificationBarSettings.isActive}
                          handle={() => {
                              setKeyBar('isActive', !headNotificationBarSettings.isActive)
                          }}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('notificationBarId', val)}
                    value={headNotificationBarSettings.notificationBarId}
                    id={'headNotificationBarSettings.notificationBarId'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'Унікальний ключ опитування'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('notificationBarText', val)}
                    value={headNotificationBarSettings.notificationBarText}
                    id={'headNotificationBarSettings.notificationBarText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст сповіщення'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => {
                        setKeyBar('notificationBarTextLink', val)
                    }}
                    value={headNotificationBarSettings.notificationBarTextLink}
                    id={'headNotificationBarSettings.notificationBarTextLink'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст, який виглядає як посилання'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    as={'textarea'} rows={5}
                    handleChange={ (val) => setKeyBar('popUpText', val)}
                    value={headNotificationBarSettings.popUpText}
                    id={'headNotificationBarSettings.popUpText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст popup-а'}
                    closeButton
                    classNamesClear={'r-23'}
                    styles={{minHeight: '150px'}}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('btnText', val)}
                    value={headNotificationBarSettings.btnText}
                    id={'headNotificationBarSettings.btnText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст кнопки'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('btnLink', val)}
                    value={headNotificationBarSettings.btnLink}
                    id={'headNotificationBarSettings.btnLink'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'посилання кнопки'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('cn_notificationBarText', val)}
                    value={headNotificationBarSettings.cn_notificationBarText}
                    id={'headNotificationBarSettings.cn_notificationBarText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст сповіщення китайською'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => {
                        setKeyBar('cn_notificationBarTextLink', val)
                    }}
                    value={headNotificationBarSettings.cn_notificationBarTextLink}
                    id={'headNotificationBarSettings.cn_notificationBarTextLink'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст, який виглядає як посилання китайською'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    as={'textarea'} rows={5}
                    handleChange={ (val) => setKeyBar('cn_popUpText', val)}
                    value={headNotificationBarSettings.cn_popUpText}
                    id={'headNotificationBarSettings.cn_popUpText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст popup-а китайською'}
                    closeButton
                    classNamesClear={'r-23'}
                    styles={{minHeight: '150px'}}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('cn_btnText', val)}
                    value={headNotificationBarSettings.cn_btnText}
                    id={'headNotificationBarSettings.cn_btnText'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'текст кнопки китайською'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <InputText
                    handleChange={ (val) => setKeyBar('cn_btnLink', val)}
                    value={headNotificationBarSettings.cn_btnLink}
                    id={'headNotificationBarSettings.cn_btnLink'}
                    groupClssses={'my-2 mw-500'}
                    placeholder={'посилання кнопки китайською'}
                    closeButton
                    classNamesClear={'r-23'}
                />
                <div className="my-2">
                    <Upload
                        value={fakeDoorsIcon}
                        saveToRedux={(imagePreviewUrl, imgData) => {
                            saveImg(imagePreviewUrl, imgData)
                        }}
                        removeImg={() => {
                            dispatch({type: SET_KEY, payload: {
                                key: 'fakeDoorsIcon',
                                value: {},
                            }})
                        }}
                    />
                </div>
                <div className="my-2">
                    <Upload
                        value={fakeDoorsModalBG}
                        saveToRedux={(imagePreviewUrl, imgData) => {
                            saveImg(imagePreviewUrl, imgData, 'fakeDoorsModalBG')
                        }}
                        removeImg={() => {
                            dispatch({type: SET_KEY, payload: {
                                key: 'fakeDoorsModalBG',
                                value: {},
                            }})
                        }}
                    />
                </div>
                <Button disabled={inSaveProces} onClick={saveHeadNotificationBarSettings} className={'hmc w-100'}>Зберегти</Button>
            </div>
            <div className="col-6">
                <div style={{height: 'calc(100% - 42px)'}}>
                    <Select
                        id={'Select_dateStatHeadNotificationBarId'}
                        classNameContainer={"align-self-center pl-0 mb-2"}
                        options={Object.keys(dateStatHeadNotificationBar)}
                        selectValue={dateStatHeadNotificationBarId}
                        handleChange={ (el, id) => {
                            setDateStatHeadNotificationBarId(el)
                        }}
                        loaded={false}
                    />
                    <RequestStatistic
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={statHeadNotificationBar || []}
                        listHead={listHead}
                        listItem={listItem}
                    />
                </div>
                <Button disabled={inDownloadProces} onClick={loadExceldNotificationBar} className={'hmc w-100'}>Скачати</Button>
            </div>
        </div>
    );
};


export default HeadNotificationBarSettings;
