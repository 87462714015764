import React, {useState} from 'react';
import ReactTooltip from "react-tooltip";

const BalanceMoney = ({className, balance, currency, successColor}) => {

    // const balance = 23.12

    return (
        <div className={className}>
            <span
                className={`${+balance < 0 ? 'my-text-danger' : successColor ? successColor : 'my-text-primary'} text-bold`}>{balance} {currency}</span>
        </div>
    );
};

export default BalanceMoney;
