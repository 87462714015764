import React              from "react";
import { Card }           from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Copyright          from "../../components/shared/Copyright";
import RegisterForm       from "../../components/forms/RegisterForm";


const Signup = () => {

    const {t} = useTranslation();

    return (
        <Card className={'w-100 border-0'}>
            <p className={'text-center'} style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
                color: '#0060AE'
            }}>{t('registration')}</p>

            <RegisterForm/>

            <Copyright/>
        </Card>
    )
}

export default Signup;
