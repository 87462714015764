import React                        from 'react';
import { useDispatch }              from "react-redux";
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";
import { setLoadSpinner }           from "../../../storage/global";
import { viewAlert }                from "../../../utils/misc";

const ExportButton = ({className, buttClassNames, handle, text, filename}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    function onClick(event) {
        if (typeof handle === 'function') {
            dispatch(setLoadSpinner(true))
            handle()
                .then(({data}) => {
                    const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(({response}) => {
                    viewAlert(dispatch, response)
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder classNames={buttClassNames}>
                <img className={'mr-1'} src="/assets/img/file-xls.svg" alt="export-button"/>
                <span>{text ? text : t('export')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default ExportButton;
