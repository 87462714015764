
import i18n                 from "i18next";

import { ASC, DEFAULT_ERROR_OBJECT, DESC } from '../constants'
import { setAlert, setList, setLoadSpinner, setModalForm, setModalForm2, setSelectedList } from "../storage/global";
import { EN, CN } from "../components/shared/LangBlock";
import {cssTransition, toast} from "react-toastify";
import React from "react";

export function unselectList(selectedList = [], all = false) {
    if (all) {
        const els = document.querySelectorAll('.table-item-selected')
        if (els && els.length) {
            els.forEach((el => el.className = el.className.replace('table-item-selected', '').trim()))
        }
    } else {
        selectedList.forEach(parcel => {
            const el = document.querySelector(`[data-uid='${parcel.uid}']`)
            if (el)
                el.className = el.className.replace('table-item-selected', '').trim()
        })
    }
}

export function selectList(selectedList = [], all = false) {
    if (all) {
        const els = document.querySelectorAll('.table-row-item')
        if (els && els.length) {
            els.forEach((el =>  el.className = el.className + ' table-item-selected'))
        }
    } else {
        selectedList.forEach(parcel => {
            const el = document.querySelector(`[data-uid='${parcel.uid}']`)
            if (el)
                el.className = el.className + ' table-item-selected'
        })
    }
}

export async function sortedList(list, name, direction, search = '', isDate = false, listForFilter) {

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    const regex = new RegExp(`(${search})`, 'i');
    return list.filter(item => {
        const itemVal = JSON.stringify(Object.values(item))
        return (!search.length && (!listForFilter || !listForFilter.length))
                || (search.length && regex.test(itemVal))
                || (listForFilter && listForFilter.find( item => itemVal.includes(item)));
    }).sort((a, b) => {
        if (isDate) {
            const date1 = a.date ?? a.sentdate ?? a.Data;
            const date2 = b.date ?? b.sentdate ?? b.Data;
            const splitDateFirst = date1.split('.');
            const splitDateSecond = date2.split('.');
            const dateFirst = new Date(`${splitDateFirst[2]}-${splitDateFirst[1]}-${splitDateFirst[0]}`);
            const dateSecond = new Date(`${splitDateSecond[2]}-${splitDateSecond[1]}-${splitDateSecond[0]}`);

            if (direction === DESC)
                return collator.compare(dateFirst.getTime(), dateSecond.getTime()) * -1
            return collator.compare(dateFirst.getTime(), dateSecond.getTime())
        } else {
            if (direction === DESC)
                return collator.compare(a[name], b[name]) * -1
            return collator.compare(a[name], b[name])
        }
    })
}

export function getDirection(currentOrder, name) {
    return currentOrder.name === name
        ? currentOrder.direction === ASC ? DESC : ASC
        : DESC
}

export function handleOrder(rootItems, name, direction, search, state, setState, iop, isDate) {

    return sortedList(rootItems, name, direction, search, isDate)
        .then((list => {
            setState({
                ...state,
                rootItems,
                items: list,
                itemsView: list.slice(0, iop),
                page: 2,
                search,
                order: {
                    name,
                    direction
                }
            })
            return list;
        }))
        .then((list) => {
            unselectList([], true)
            return list;
        })
}

export function requestDeleteMethod(dispatch, getList, deleteMethod, t) {
    return (selectedList) => {
        return deleteMethod(selectedList)
            .then((response) => {
                viewAlert(dispatch, response)
            })
            .then(() => {
                dispatch(setSelectedList([]))
                dispatch(setLoadSpinner(false));
                getList()
                    .then(response => {
                        dispatch(setList(response.data, t('noStatus')))
                    })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
                    .finally(() => dispatch(setLoadSpinner(false)));
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })
    }
}

export function submitFormByRef(ref) {
    ref.current.querySelector('[type="submit"]').click()
}

export function serializeFormToObject(form:Object) {
    const data = {};

    Object.values(form)
        .filter(item => ['SELECT', 'INPUT'].includes(item.tagName))
        .map(value => {
            return {
                name: value.id,
                value: value.value
            }
        })
        .map(v => data[`${v.name}`] = v.value)

    return data;
}

export function serializeFormToArray(form) {

    return Object.values(form)
        .filter(item => ['SELECT', 'INPUT'].includes(item.tagName))
        .map(value => {
            return {
                name: value.id,
                value: value.value
            }
        })
}

export function hideModalForm(dispatch) {
    return () => {
        dispatch(setModalForm({
            show: false
        }))
    }
}

export function hideModalForm2(dispatch) {
    return () => {
        dispatch(setModalForm2({
            show: false
        }))
    }
}

export function validate(value, validations, setError, defaultError) {
    const newError = {...defaultError}
    if (validations && Array.isArray(validations)) {
        validations.forEach(f => {
            const message = f(value);
            if (message.length && !newError.isError) {
                newError.isError = true;
                newError.message = message;
            }
        })
    }
    setError && setError(newError)
    return newError;
}

export function checkValidate(value, validations, setError) {
    return validate(value, validations, setError, DEFAULT_ERROR_OBJECT);
}

export function viewAlert(dispatch:Object, response:Object, alert:Object) {
    if (!response)
        response = {status: 0, statusText: ''}
    if (response.status >= 400) {
        dispatch(setAlert({
            text: response && response.statusText && response.statusText.match(/(Resolving timed out after)|(Operation timed out)/ig) ? 'An error has occurred. Please try again later.' : response.statusText,
            type: 'danger',
            ...alert,
        }))
    } else {
        dispatch(setAlert({
            text: response.statusText,
            type: 'success',
            ...alert,
        }))
    }
}

export function modifyPhoneNumber(value) {
    return value.toString().replaceAll(/\D/ig, '');
}

export function goTrackingSystem(uid:string, dispatch) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = 'https://track.meestcn.cn/?search=' + uid
    a.click();
}

export function upperCaseFirstLetter(text:string) {
    return text.length > 1 ? text.slice(0, 1).toUpperCase() + text.slice(1) : text.toUpperCase();
}

export function parsePassport(value:string|[]):{series: string, number: number} {
    if (Array.isArray(value))
        value = value.join();
    if (value && value.length > 2) {
        return {series: value.slice(0, 2), number: value.slice(2)}
    }
    return {series:'', number:value}
}

export function getExactTime(time: string, withHour: boolean) {
    const data = new Date(time);
    const d = data.getDate();
    const mo = (Number(data.getMonth()) + 1);
    const h = data.getHours();
    const m = data.getMinutes();
    const data_decode = time ?
        add0(d) + '.' + add0(mo) + '.' + data.getFullYear() +
            (withHour ? (' ' + add0(h) + ':' + add0(m)) : '')
        : time;

    return data_decode;
}

export function getExactTimeFullYear(time: string, withHour: boolean) {
    const data = new Date(time);
    const d = data.getDate();
    const mo = (Number(data.getMonth()) + 1);
    const h = data.getHours();
    const m = data.getMinutes();
    const s = data.getSeconds();
    const data_decode = time ?
        data.getFullYear() + '-' + add0(mo) + '-' + add0(d) + ' ' +
            (withHour ? (' ' + add0(h) + ':' + add0(m) + ':' + add0(s)) : '')
        : time;

    return data_decode;
}

const add0 = (n: number) => (n < 10) ? ('0' + n) : n;

export const translateStatus = (postStatus: StatusInterface): string => {
    switch (i18n.language) {
        case EN:
            return postStatus.name_en;
        case CN:
            return postStatus.name_cn;
        default:
            return postStatus.name_en;
    }
}

export const getStatus = (statuses: any[], statusCode: string): string => {
    if (!statusCode) return null;
    const c = statuses.find( c => c.code == statusCode);
    return c ? translateStatus(c) : statusCode;
}

export function copy(value: string) {
    const zoom = cssTransition({
        enter: "animate__animated animate__zoomIn",
        exit: "animate__animated animate__zoomOut"
    })

    navigator.clipboard.writeText(value)
        .then(() => {
            return toast.info(<div className={'text-center'}>{i18n.t('copied')}</div>, {
                autoClose: 500,
                position: "bottom-center",
                theme: 'colored',
                transition: zoom,
                hideProgressBar: true
            });
        })
        .then(id => {
            setTimeout(() => {
                const elem = document.getElementById(id);
                if (elem) {
                    elem.style.width = '200px'
                }
            }, 5)
            // if (elem)
        })
        .catch()
}

export function debounce<A = unknown, R = void>(
    fn: (args: A) => Promise<R>, ms: number
): [(args: A) => Promise<R>, () => void] {

    let timer: NodeJS.Timeout;

    const debouncedFunc = (args: A): Promise<R> =>
        new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(() => {
                resolve(fn(args));
            }, ms);
        });

    const teardown = () => clearTimeout(timer);

    return [debouncedFunc, teardown];
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}