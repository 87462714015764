import React, {useEffect, useMemo, useState} from 'react';

import localStyles from './styles.module.scss'
import Card from "../../shared/Card";
import NotificationList from "./NotificationList";

const NotificationDropDown = ({show, close, parentElementRef}) => {

    return (
        <Card className={``} dataShow={show}>
            <span className={localStyles.closeButton} onClick={close}><img src={'/assets/img/close.svg'} alt="Close" width={24} height={24}/></span>
            <div className={`${localStyles.itemsContainer}`}>
                <NotificationList close={close}/>
            </div>
        </Card>
    );
};

export default NotificationDropDown;
