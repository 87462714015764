import React, { useState }                                                from 'react';
import { Form }                                                           from "react-bootstrap";
import { useTranslation }                                                 from "react-i18next";
import { useDispatch, useSelector }                                       from "react-redux";

import Switch                                                             from "../input/Switch";
import InputText                                                          from "../input/InputText";
import ModalButtons                                                       from "../shared/modal/ModalButtons";
import { setLoadSpinner }                                                 from "../../storage/global";
import { DEFAULT_ERROR_OBJECT, LOCAL_TRACK_NUMBER, REGISTER_NAME }        from "../../constants";
import { importNewParcelFile }                                            from "../../utils/api";
import { checkValidate, hideModalForm, serializeFormToObject, viewAlert } from "../../utils/misc";
import { noEmptyValidate }                                                from "../../utils/validate";

const accessMimeType = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12'
];

const ImportForm = ({ getListParcels }) => {
    const loadSpinner = useSelector(state => state.global.spinner);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const validations = {}
    validations[REGISTER_NAME] = [noEmptyValidate(t('validEmpty'))]
    validations[LOCAL_TRACK_NUMBER] = [noEmptyValidate(t('validEmpty'))]

    const [file, setFile] = useState(null);
    const [useIOSS, setUseIOSS] = useState(false);
    const [registerError, setRegisterError] = useState({...DEFAULT_ERROR_OBJECT})
    const [trackNumberError, setTrackNumberError] = useState({...DEFAULT_ERROR_OBJECT})

    const isAgent = useSelector(state => state.global.isAgent)

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        if(loadSpinner) return;

        const data = serializeFormToObject(e.currentTarget)

        const errors = [
            checkValidate(data[REGISTER_NAME], validations[REGISTER_NAME], setRegisterError),
            checkValidate(data[LOCAL_TRACK_NUMBER], validations[LOCAL_TRACK_NUMBER], setTrackNumberError)
        ]

        const isError = errors.find(value => value.isError)

        if (!isError) {
            if (file) {

                dispatch(setLoadSpinner(true))

                const requestData = new FormData();
                requestData.append('useIOSS', +useIOSS)
                requestData.append('file', file)
                requestData.append('filename', file.name)
                requestData.append(REGISTER_NAME, data[REGISTER_NAME])
                requestData.append(LOCAL_TRACK_NUMBER, data[LOCAL_TRACK_NUMBER])

                importNewParcelFile(requestData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then((response) => {
                        const {data} = response;
                        const euCountries = data.countryList.filter(c => c.europe)
                        const errorIossMessage = t('errorIossMessage')
                        const errorProhibitedCountry = t('errorProhibitedCountry')
                        let html = <>
                            {
                                data && data.errors && data.errors.ioss?.length
                                    ? data.errors.ioss.length ? <>
                                        <p>{errorIossMessage.replace(/({\$1})/ig, euCountries.map(c => c.country_name_en.toUpperCase()).join(', '))}</p>
                                        <hr/>
                                    </> : ''
                                    : null
                            }
                            {
                                data && data.errors && data.errors.prohibitedCountry?.length
                                    ? <>
                                        <p>{errorProhibitedCountry.replace(/({\$1})/ig, data.errors.prohibitedCountry.join(', '))}</p>
                                        <hr/>
                                    </>
                                    : null
                            }
                            {
                                data && data.errors && data.errors.serviceType && data.errors.serviceType.length
                                    ? <>
                                        <p>{data.errors.serviceType.map(st => {
                                            return <>
                                                <p>{t('row')} - {st.row}</p>
                                                <p>{t('message')} - {st.message}</p>
                                                <hr/>
                                                <br/>
                                            </>
                                        })}
                                        </p>
                                    </>
                                    : null
                            }
                            {
                                data && data.errors && data.errors.request?.length
                                    ?
                                    <p>{data.errors.request.map(r => {
                                        return <>
                                            <p>{t('row')} - {r.row}</p>
                                            <p>{t('message')} - {r.message}</p>
                                            <hr/>
                                            <br/>
                                        </>
                                    })}
                                    </p>
                                    : null
                            }
                            {
                                data && data.errors && data.errors.name?.length
                                    ?
                                    <p>{data.errors.name.map(r => {
                                        return <>
                                            <p>{t('row')} - {r.row}</p>
                                            <p>{t('message')} - {r.message}</p>
                                            <hr/>
                                            <br/>
                                        </>
                                    })}
                                    </p>
                                    : null
                            }
                            {
                                data && data.success && data.success.length
                                    ? <>
                                        <strong>{t('success')} ({data.success.length}) :</strong>
                                        <br/>
                                        <span>{t('rows')} - </span>
                                        {data.success.map((s, k) =>
                                            <span>{s.row}{data.success.length - 1 !== k ? ', ' : ''}</span>)}
                                    </>
                                    : null
                            }
                        </>
                        if (data && data.errors &&
                            (data.errors.ioss.length
                                || data.errors.prohibitedCountry.length
                                || data.errors.request.length
                                || data.errors.name.length
                            )
                        ) {
                            const typeAlert = response.status >= 400 || (data.status_code_1C && data.status_code_1C >= 400) ? 'danger' : 'warning'
                            viewAlert(dispatch, response, {text: html, type: typeAlert, title: t(typeAlert === 'warning' ? 'warning' : 'error')})
                        } else
                            viewAlert(dispatch, response, {text: html, type: 'success', title: t('successImport')})
                            hideForm();
                            if (getListParcels) {
                                getListParcels()
                            }
                    })
                    .catch((err) => {
                        console.log("🚀 importNewParcelFile catch err:", err);
                        viewAlert(dispatch, err.response)
                    })
                    .finally(() => dispatch(setLoadSpinner(false)))
            } else {
                viewAlert(dispatch, {status: 400}, {
                    text: t('fileNotFound'),
                    type: 'warning'
                })
            }
        }

    }

    function handleValues(value, name, inputRef) {

        if (name === 'import-file') {
            if (inputRef && inputRef.current && inputRef.current.files.length && inputRef.current.files[0]) {
                setFile(inputRef.current.files[0])
            } else {
                setFile(null)
            }
        } else if (name === REGISTER_NAME) {
            checkValidate(value, validations[name], setRegisterError)
        } else if (name === LOCAL_TRACK_NUMBER) {
            checkValidate(value, validations[name], setTrackNumberError)
        }
    }

    function hideForm() {
        hideModalForm(dispatch)()
    }

    return (
        <Form noValidate onSubmit={handleSubmit}>
            <table className={'w-100'}>
                <tbody>
                <tr>
                    <td colSpan={2}>
                        <InputText
                            id={'import-file'}
                            type={'file'}
                            classes={''}
                            handleChange={handleValues}
                            acceptFiles={accessMimeType.join(',')}
                            selectLabelText={file ? file.name : t('selectFile')}
                            label
                            hidden
                            closeButton
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Switch
                            id={'ioss_switch'}
                            labelText={'Set Default IOSS Number'}
                            defaultValue={useIOSS}
                            handle={value => setUseIOSS(value)}
                        />
                    </td>
                </tr>
                {
                    isAgent && false
                        ?
                        <>
                            <tr>
                                <td>Register Name</td>
                                <td>
                                    <InputText
                                        id={REGISTER_NAME}
                                        classes={'text-center mt-2'}
                                        handleChange={handleValues}
                                        handleBlur={handleValues}
                                        placeholder={t('name')}
                                        closeButton
                                        errorMessage={registerError.message}
                                        validations={validations[REGISTER_NAME]}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Local Track Number</td>
                                <td>
                                    <InputText
                                        id={LOCAL_TRACK_NUMBER}
                                        classes={'text-center mt-3'}
                                        handleChange={handleValues}
                                        handleBlur={handleValues}
                                        placeholder={t('track_number')}
                                        closeButton
                                        errorMessage={trackNumberError.message}
                                        validations={validations[LOCAL_TRACK_NUMBER]}
                                    />
                                </td>
                            </tr>
                        </>
                        : null
                }
                </tbody>
            </table>
            <ModalButtons buttons={{
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideForm,
                },
                import: {
                    text: t('import'),
                    variant: 'primary',
                    type: 'submit',
                    disabled: loadSpinner,
                }
            }}/>
        </Form>
    );
};

export default ImportForm;
