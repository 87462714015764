import React         from 'react';
import { useDispatch, useSelector }  from "react-redux";
import { useTranslation }           from "react-i18next";

import HelpVideo     from "./HelpVideo";
import ExcelTemplate from "./ExcelTemplate";
import BalanceButton from "./BalanceButton";
import NotifBtn      from "./NotifBtn";
import LangBlock     from "../shared/LangBlock";
import ProfileButton from "./ProfileButton";
import LogoutButton  from "./LogoutButton";
import ButtonSmallNoBorder   from "../shared/ButtonSmallNoBorder";
import { setModalForm, setShowMenu } from "../../storage/global";
import FeedbackForm                   from "../forms/FeedbackForm";

const HeaderLarge = ({className}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    function writeReport() {
        dispatch(setModalForm({
            title: <div style={{wordWrap: "break-word", marginRight: '46px'}}>{t('feedback_title')}</div>,
            form: <FeedbackForm/>,
            formContent: {classNames: ''},
            hideOut: true
        }))
    }
    return (
        <div className={`row no-gutters ${className}`}>
            <div className={'d-flex align-items-center justify-content-start col-auto'}>
                <HelpVideo className={'mr-5'}/>
                <ExcelTemplate/>
            </div>
            <div className={"d-flex align-items-center justify-content-end col"}>
                <div onClick={writeReport}
                     className={`mr-2`}
                     style={{
                        width: '120px',
                        height: '24px',
                     }}
                >
                    <div className={'cursor-pointer'}
                         style={{
                            width: '100%',
                            marginTop: '-7px',
                         }}
                    >
                        <img className={'w-100'} src={`/assets/img/feedback.png`} alt="new"/>
                    </div>
                </div>
                <BalanceButton/>
                <NotifBtn classNameContainer={'ml-2'}/>
                <LangBlock className={'ml-2'}/>
                <ProfileButton className={'ml-5 cursor-pointer'}/>
                <LogoutButton className={'ml-3'}/>
            </div>
        </div>
    );
};

export default HeaderLarge;
