import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate }        from "react-router-dom";
import { useDispatch }                from "react-redux";

import { setLoad }                    from "../../storage/global";
import { routesNames }                from "../../constants";
import Logo                           from "../../components/shared/Logo";
import LangBlock                      from "../../components/shared/LangBlock";
import { getUserInfo }                from "../../utils/api";

const RootAuth = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAccess, setData] = useState(false);

    useEffect( () => {
        dispatch(setLoad(true))
        getUserInfo()
            .then(response => {
                navigate(`/${routesNames.NEW_PARCELS}`)
            })
            .catch(({response}) => {
                console.log('getUserInfo catch: ', response)
            })
            .finally(() => {
                setData(true)
                dispatch(setLoad(false))
            })
    }, [])

    return (
        <>
            {
                isAccess
                // !load
                    ?
                    <div className="container-fluid h-100 d-flex flex-column">
                        <div className="z-index-2 row align-items-center pt-3 p-lg-4 mb-5">
                            <div className="col">
                                <Logo/>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <LangBlock className={'text-left'}/>
                            </div>
                        </div>
                        <div className="row h-100 d-flex justify-content-center align-items-center login-form-top">
                            <div style={{
                                maxWidth: '476px',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '16px',
                                boxShadow: '0px 1px 2px rgba(23, 23, 37, 0.12)',
                            }}
                                 className={'p-3 px-md-5 pt-md-4'}
                            >
                                <Outlet/>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    );
}
;

export default RootAuth;
