import React, { memo }         from 'react';

import MenuItemGroup from "./MenuItemGroup";
import Logo          from "../shared/Logo";
import CopyrightMenu from "../shared/CopyrightMenu";
import Faq from "./Faq";
import Wh from "./WH";
import ContactUs from "./ContactUs";

const MenuLarge = (props) => {



    return (
        <aside
            className={'background-primary aside-large'}
        >
            <Logo id={'logo-large'} type={'white'} className={'d-flex justify-content-center align-items-center'}/>
            <MenuItemGroup navList={props.navList} theme={'light'} style={{
                overflowY: 'auto',
                maxHeight: 'calc(100% - 320px)'
            }}/>
            <Wh/>
            <Faq/>
            <ContactUs/>
            <CopyrightMenu/>
        </aside>
    );
};

export default memo(MenuLarge);
