import React              from 'react';
import { useTranslation } from "react-i18next";

const ExcelTemplate = ({className}) => {

    const {t} = useTranslation();

    function downloadTemplate() {
        const a = document.createElement('a')
        a.target = '_blank';
        a.href = '/assets/files/download_list_form.xls';
        a.click();
    }

    return (
        <div className={className}>
            <div onClick={downloadTemplate} className={'d-flex justify-content-between align-items-center cursor-pointer'}>
                <img src="/assets/img/download.svg" alt="download"/>
                <span className={'my-text-primary ml-1'}>{t('excel_template_download')}</span>
            </div>
        </div>
    );
};

export default ExcelTemplate;
