import React              from "react";
import { useSelector }    from "react-redux";
import { Card }           from "react-bootstrap";
import { useTranslation } from "react-i18next";

import Copyright          from "../../components/shared/Copyright";
import RestoreForm        from "../../components/forms/RestoreForm";
import NewPasswordForm        from "../../components/forms/NewPasswordForm";

const RestorePassword = () => {

    const {t} = useTranslation();

    const { connection_id } = useSelector(state => state.global.restorePasswordData)

    return (
        <Card className={'w-100 border-0'}>
            <p className={'text-center'} style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
                color: '#0060AE'
            }}>{t('restore_password')}</p>

            {connection_id ?
                <NewPasswordForm/>
                : <RestoreForm/>
            }

            <Copyright/>
        </Card>
    )
}

export default RestorePassword;
