import React, { memo }              from 'react';
import { useSelector }              from "react-redux";

const MenuItemGroup = ({className, style, theme, navList, ...props}) => {

    const profile = useSelector(state => state.global.profile)

    const lightTheme = theme === 'light';

    return (
        <ul
            className={`nav-menu position-relative ${className ?? ''} thin-scroll`}
            style={{...style}}
        >
            {
                navList.map((item, key) =>
                    <li
                        style={{...item.styles}}
                        hidden={item.admin && profile.username !== 'od@rosanexpress.com'}
                        key={key}
                        onClick={item.handle}
                        className={`cursor-pointer nav-item ${item.className ?? ''} ${lightTheme ? 'my-text-white' : 'my-text-primary'} ${item.active ? 'active-nav-item' : ''}`}
                    >
                        { item?.ico ? <img className={'mr-1'} src={item.ico.src} alt={item.ico.alt}/> : null }
                        <span style={{...item.spanStyles}}>{item.title}</span>
                        {item?.afterChildren}
                    </li>
                )
            }
        </ul>
    );
};

export default memo(MenuItemGroup);
