import React      from 'react';
import { Button } from "react-bootstrap";

const ButtonSmallNoBorder = ({classNames, children, variant, pClasses = 'p-1'}) => {

    return (
        <Button className={`${pClasses} ${classNames ?? ''}`} variant={`${variant ?? 'outline-primary-no-border'}`}>
            {children}
        </Button>
    );
};

export default ButtonSmallNoBorder;
